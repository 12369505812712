<template>
  <div class="contact container">
    <div class="header">
      <div class="title-box">
        <p class="title">联系我们</p>
      </div>
    </div>
    <p class="title">联系我们</p>
    <div class="content">
      <p>{{ art.sub }}</p>
      <p v-for="(p, i) in art.content" :key="i" style="color: red;">{{p}}</p>
    </div>
  </div>
</template>

<script>
import { contact } from '@/src/config/copywriting'
export default {
  name: 'Contact',
  data () {
    return {
      art: contact
    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
.contact {
  padding-top: 30px;
  padding-bottom: 40px;

  > .header {
    padding: 0 18px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    > .title-box {
      display: flex;
      align-items: center;
      height: 45px;

      > .title {
        font-size: 18px;
        font-weight: 500;
        color: #222;
      }
    }
  }

  > .title {
    padding-top: 40px;
    padding-bottom: 30px;
    font-size: 32px;
    font-weight: 400;
    color: #222;
    text-align: center;
    background-color: #fff;
  }

  > .time {
    font-size: 14px;
    color: #959595;
    text-align: center;
    background-color: #fff;
  }

  > .content {
    margin: 0;
    padding: 36px 30px;
    padding-top: 50px;
    font-size: 16px;
    line-height: 32px;
    word-break: break-all;
    word-wrap: break-word;
    text-align: justify;
	  text-justify: inter-word;
    background-color: #fff;
  }
}
</style>

